import { Stack, Box, Paper, Typography, Button } from "@mui/material";
import React from "react";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Image404 from "assets/notfound.png";

type Props = {};

const styles = {
  paperContainer: {
    backgroundImage: `url(${Image404})`,
  },
};

const NotFound = (props: Props) => {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      height="100vh"
      spacing={3}
      style={styles.paperContainer}
    >
      <Box component={Paper} sx={{ p: 4 }}>
        <Typography variant="h3">Not Found</Typography>
        <Typography variant="body1">
          The current page doesn't exist. Try the following options instead:
        </Typography>
        <Link component={RouterLink} to={"/"}>
          <Button color="primary" variant="text">
            Go Home
          </Button>
        </Link>
        <Link component={RouterLink} to={"/login"}>
          <Button color="primary" variant="text">
            Login
          </Button>
        </Link>
      </Box>
    </Stack>
  );
};

export default NotFound;
