import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import firebaseApp from "services/firebase.storage";

export type TSuccessUploadCallback = (downloadURL: string) => void;
export const fileUpload = (
  file: File,
  callback: TSuccessUploadCallback,
  msgCallback: (text: string, color: "success" | "error") => void
) => {
  const fileName = new Date().getTime() + file.name;
  const storage = getStorage(firebaseApp);
  const StorageRef = ref(storage, fileName);

  const uploadTask = uploadBytesResumable(StorageRef, file);

  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
        default:
          console.log("Unknown firebase ap state");
      }
    },
    (error) => {
      // Handle unsuccessful uploads
      msgCallback(`File upload error: ${JSON.stringify(error)}`, "error");
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log("File available at", downloadURL);
        callback(downloadURL);
      });
      msgCallback("File upload is successful!", "success");
    }
  );
};
