import {
  Box,
  Button,
  Container,
  Stack,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsDispatcher } from "state/async";
import { deleteProductDispatcher } from "../../state/async/products.actions";
import { selectProducts } from "../../state/selectors/product.selectors";
import ProductColumns from "./ProductsColumns";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";

export default function Products() {
  const products = useSelector(selectProducts);
  const [pageSize, setPageSize] = useState(8);
  const dispatch = useDispatch();
  useEffect(() => {
    getProductsDispatcher(dispatch);
  }, [dispatch]);

  const handleDelete = (id: string) => {
    deleteProductDispatcher(id, dispatch);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ m: 1 }} variant="h4">
        Products list
      </Typography>
      <Stack
        component={Paper}
        sx={{
          p: 2,
          width: "100%",
        }}
      >
        <div style={{ flexGrow: 0 }}>
          <DataGrid
            autoHeight
            rows={products}
            getRowId={(row) => row._id}
            disableSelectionOnClick
            columns={ProductColumns(handleDelete)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 8, 12, 20]}
            pagination
          />
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <Link to={"/product/new"} component={RouterLink} underline="none">
            <Button color="primary" variant="contained">
              Create New Product
            </Button>
          </Link>
        </Box>
      </Stack>
    </Container>
  );
}
