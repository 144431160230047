import { INewProduct } from "interfaces";
import axios from "axios";
import { apiServerHost } from "config";
import { IProduct } from "interfaces/products";

export const getProductById = (id: string) =>
  axios.get<IProduct>(`${apiServerHost}/products/find/` + id, {
    withCredentials: true,
  });

export const getProducts = (cat?: string | undefined) =>
  axios.get<IProduct[]>(
    `${apiServerHost}/products${cat ? `?category=${cat}` : ""}`
  );

export const deleteProductById = (id: string) =>
  axios.delete<string>(`${apiServerHost}/products/` + id, {
    withCredentials: true,
  });

export const updateProductById = (id: string, product: Partial<IProduct>) =>
  axios.put<IProduct>(`${apiServerHost}/products/` + id, product, {
    withCredentials: true,
  });

export const addProduct = (product: Partial<INewProduct>) =>
  axios.post<IProduct>(`${apiServerHost}/products`, product, {
    withCredentials: true,
  });
