import { createSlice } from "@reduxjs/toolkit";
import { IProductState } from "interfaces";
import {
  getProductStartReducer,
  getProductFailureReducer,
  getProductSuccessReducer,
  deleteProductFailureReducer,
  deleteProductStartReducer,
  deleteProductSuccessReducer,
  updateProductStartReducer,
  updateProductSuccessReducer,
  updateProductFailureReducer,
  addProductStartReducer,
  addProductSuccessReducer,
  addProductFailureReducer,
} from "state/reducers";

const initialState: IProductState = {
  products: [],
  isFetching: false,
  error: false,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    getProductStart: getProductStartReducer,
    getProductSuccess: getProductSuccessReducer,
    getProductFailure: getProductFailureReducer,
    deleteProductStart: deleteProductStartReducer,
    deleteProductSuccess: deleteProductSuccessReducer,
    deleteProductFailure: deleteProductFailureReducer,
    updateProductStart: updateProductStartReducer,
    updateProductSuccess: updateProductSuccessReducer,
    updateProductFailure: updateProductFailureReducer,
    addProductStart: addProductStartReducer,
    addProductSuccess: addProductSuccessReducer,
    addProductFailure: addProductFailureReducer,
  },
});

export const {
  getProductStart,
  getProductSuccess,
  getProductFailure,
  deleteProductStart,
  deleteProductSuccess,
  deleteProductFailure,
  updateProductStart,
  updateProductSuccess,
  updateProductFailure,
  addProductStart,
  addProductSuccess,
  addProductFailure,
} = productSlice.actions;
// export const { reducer: productReducer } = productSlice;
