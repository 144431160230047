import { IOrder } from "interfaces";
import { Dispatch } from "redux";
import { deleteOrderById, getOrders, updateOrderById } from "services";
import {
  deleteOrderFailure,
  deleteOrderStart,
  deleteOrderSuccess,
  getOrderFailure,
  getOrderStart,
  getOrderSuccess,
  updateOrderFailure,
  updateOrderStart,
  updateOrderSuccess,
} from "state/slices";

export const getOrdersDispatcher = async (dispatch: Dispatch) => {
  dispatch(getOrderStart());
  try {
    const { data: OrderData } = await getOrders();
    dispatch(getOrderSuccess(OrderData));
  } catch (error) {
    dispatch(getOrderFailure());
  }
};

export const deleteOrderDispatcher = async (id: string, dispatch: Dispatch) => {
  dispatch(deleteOrderStart());
  try {
    await deleteOrderById(id);
    dispatch(deleteOrderSuccess({ id }));
  } catch (error) {
    dispatch(deleteOrderFailure());
  }
};

export const updateOrderDispatcher = async (
  id: string,
  updateOrder: Partial<IOrder>,
  dispatch: Dispatch
) => {
  dispatch(updateOrderStart());
  try {
    const { data: order } = await updateOrderById(id, updateOrder);
    dispatch(updateOrderSuccess({ id, order }));
  } catch (error) {
    dispatch(updateOrderFailure());
  }
};
