import {
  Avatar,
  Chip,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { format } from "timeago.js";

const statusColor: any = {
  approved: "success",
  pending: "primary",
  declined: "error",
};

type Props = {
  order: any;
};

const TransactionRow = ({ order }: Props) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        <Stack direction="row" spacing={2} alignItems="center">
          <Tooltip title={`User ID: ${order.userId}`}>
            <Avatar
              src="https://images.pexels.com/photos/4172933/pexels-photo-4172933.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              alt={order.userId}
            />
          </Tooltip>

          <Typography
            variant="subtitle2"
            noWrap
            component="span"
            sx={{ display: { xs: "none", md: "inline-block" } }}
          >
            {order.userId}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell sx={{ display: { xs: "none", xl: "table-cell" } }}>
        {format(order.createdAt)}
      </TableCell>
      <TableCell>${order.amount}</TableCell>
      <TableCell>
        <Chip
          label={order.status}
          color={statusColor[order.status]}
          variant="outlined"
        />
      </TableCell>
    </TableRow>
  );
};

export default TransactionRow;
