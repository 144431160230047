import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React, { MouseEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutDispatcher } from "../../state/async/user.actions";
import ButtonBar from "./ButtonBar";
import MobileMenu from "./DropdownMenu/MobileMenu";
import PrimaryMenu from "./DropdownMenu/FullsizeMenu";
import { useNavigate } from "react-router-dom";
import { selectLoggedUserId } from "state";
import { MenuActions } from "constant";
import { LayoutSettingsProps } from "components/Layout/Layout";
// const pages = ["Products", "Pricing", "Blog"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const SpacerGap: React.FC = () => <Box sx={{ flexGrow: 1 }} />;

const user = {
  img: "https://images.pexels.com/photos/1526814/pexels-photo-1526814.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
};

const Navbar: React.FC<LayoutSettingsProps> = ({
  settings: { toggleSideBar: toggleSidebar, openSidebar, drawerWidth },
}) => {
  const idUser = useSelector(selectLoggedUserId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  // const navigate = useNavigate();
  const handleMobileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const actionsHandler = (action: MenuActions) => {
    try {
      if (action === MenuActions.LOGOUT) {
        logoutDispatcher(dispatch);
        // Server cookie should be cleared
      }
      if (action === MenuActions.ACCOUNT) {
        navigate("/user/" + idUser);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMobileMenuClose = (action: MenuActions) => {
    setMobileMoreAnchorEl(null);
    actionsHandler(action);
  };

  const handleMenuClose = async (action: MenuActions) => {
    //Clean up UI callback
    setAnchorEl(null);
    handleMobileMenuClose(action);
  };
  const handleSidebarToggle = () => {
    toggleSidebar();
  };
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: {
            xs: "100%",
            lg: `calc(100% - ${drawerWidth}px)`,
          },
          ml: { xs: 0, lg: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleSidebarToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{ mr: 2, display: "flex" }}
          >
            SADHANA
            <Typography
              variant="h6"
              noWrap
              component="span"
              sx={{ mr: 2, display: { xs: "none", lg: "flex" } }}
            >
              &nbsp;:&nbsp;Admin Dashboard
            </Typography>
          </Typography>

          <SpacerGap />

          <ButtonBar
            handleMobileMenuOpen={handleMobileMenuOpen}
            handleProfileMenuOpen={handleProfileMenuOpen}
            user={user}
          />
        </Toolbar>
      </AppBar>
      <MobileMenu
        handleMobileMenuClose={handleMobileMenuClose}
        isMobileMenuOpen={isMobileMenuOpen}
        mobileMoreAnchorEl={mobileMoreAnchorEl}
        handleProfileMenuOpen={handleProfileMenuOpen}
      />
      <PrimaryMenu
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
        isMenuOpen={isMenuOpen}
      />
    </>
  );
};

export default Navbar;
