import axios from "axios";
import { apiServerHost } from "config";
import { IIncome, IOrder } from "interfaces";

export const getOrders = () =>
  axios.get<IOrder[]>(`${apiServerHost}/orders`, { withCredentials: true });

export const getOrdersIncome = () =>
  axios.get<IIncome[]>(`${apiServerHost}/orders/income`, {
    withCredentials: true,
  });

export const getOrdersIncomeByProductId = (pid: string) =>
  axios.get<any[]>(`${apiServerHost}/orders/income?pid=${pid}`, {
    withCredentials: true,
  });

export const deleteOrderById = (id: string) =>
  axios.delete<string>(`${apiServerHost}/orders/` + id, {
    withCredentials: true,
  });

export const updateOrderById = (id: string, order: Partial<IOrder>) =>
  axios.put<IOrder>(`${apiServerHost}/orders/` + id, order, {
    withCredentials: true,
  });
