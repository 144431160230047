import {
  AttachMoney,
  BarChart,
  LineStyle,
  PermIdentity,
  Storefront,
  Timeline,
  TrendingUp,
} from "@mui/icons-material";
import { IListOption } from "interfaces/sidebar";

export const listOptions: IListOption[] = [
  {
    header: "Dashboard",
    list: [
      {
        link: "/",
        icon: <LineStyle className="sidebarIcon" />,
        tag: "Home",
        active: true,
      },
    ],
  },
  {
    header: "Quick Menu",
    list: [
      {
        link: "/users",
        icon: <PermIdentity className="sidebarIcon" />,

        tag: "Users",
        active: true,
      },
      {
        link: "/products",
        icon: <Storefront className="sidebarIcon" />,
        tag: "Products",
        active: true,
      },
      {
        link: "/orders",
        icon: <AttachMoney className="sidebarIcon" />,
        tag: "Orders",
        active: true,
      },
    ],
  },

  {
    header: "Analytics",
    list: [
      {
        link: "/",
        icon: <Timeline className="sidebarIcon" />,
        tag: "Usage",
        active: false,
      },
      {
        link: "/",
        icon: <BarChart className="sidebarIcon" />,
        tag: "Reports",
        active: false,
      },
      {
        link: "/",
        icon: <TrendingUp className="sidebarIcon" />,
        tag: "Sales",
        active: false,
      },
    ],
  },
  // {
  //   header: "Notifications",
  //   list: [
  //     {
  //       link: "/",
  //       icon: <MailOutline className="sidebarIcon" />,

  //       tag: "Mail",
  //       active: false,
  //     },
  //     {
  //       link: "/",
  //       icon: <DynamicFeed className="sidebarIcon" />,
  //       tag: "Feedback",
  //       active: false,
  //     },
  //     {
  //       link: "/",
  //       icon: <ChatBubbleOutline className="sidebarIcon" />,
  //       tag: "Messages",
  //       active: false,
  //     },
  //   ],
  // },
  // {
  //   header: "Staff",
  //   list: [
  //     {
  //       link: "/",
  //       icon: <WorkOutline className="sidebarIcon" />,
  //       tag: "Manage",
  //       active: false,
  //     },
  //     {
  //       link: "/",
  //       icon: <Timeline className="sidebarIcon" />,
  //       tag: "Analytics",
  //       active: false,
  //     },
  //     {
  //       link: "/",
  //       icon: <Report className="sidebarIcon" />,
  //       tag: "Reports",
  //       active: false,
  //     },
  //   ],
  // },
];
