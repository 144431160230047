import { PayloadAction } from "@reduxjs/toolkit";
import { IOrder, IOrderState } from "interfaces";

// GET Order
export const getOrderStartReducer = (state: IOrderState) => {
  state.isFetching = true;
  state.error = false;
};

export const getOrderSuccessReducer = (
  state: IOrderState,
  action: PayloadAction<IOrder[]>
) => {
  state.orders = action.payload;
  state.isFetching = false;
  state.error = false;
};

export const getOrderFailureReducer = (state: IOrderState) => {
  state.isFetching = false;
  state.error = true;
};

// DELETE Order
export const deleteOrderStartReducer = (state: IOrderState) => {
  state.isFetching = true;
  state.error = false;
};

export const deleteOrderSuccessReducer = (
  state: IOrderState,
  action: PayloadAction<{ id: string }>
) => {
  state.orders.splice(
    state.orders.findIndex((item) => item._id === action.payload.id),
    1
  );
  state.isFetching = false;
  state.error = false;
};

export const deleteOrderFailureReducer = (state: IOrderState) => {
  state.isFetching = false;
  state.error = true;
};

// UPDATE(put) Order data
export const updateOrderStartReducer = (state: IOrderState) => {
  state.isFetching = true;
  state.error = false;
};

export const updateOrderSuccessReducer = (
  state: IOrderState,
  action: PayloadAction<{ id: string; order: IOrder }>
) => {
  state.orders[
    state.orders.findIndex((item) => item._id === action.payload.id)
  ] = action.payload.order;
  state.isFetching = false;
  state.error = false;
};

export const updateOrderFailureReducer = (state: IOrderState) => {
  state.isFetching = false;
  state.error = true;
};
