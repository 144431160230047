import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { LayoutSettingsProps } from "components/Layout/Layout";
import * as React from "react";
import MenuListPanel from "./MenuListPanel";

const ResponsiveSidebar: React.FC<LayoutSettingsProps> = ({
  settings: { toggleSideBar: toggleSidebar, openSidebar, drawerWidth },
}) => {
  const handleToggleDrawer = () => toggleSidebar();

  return (
    <Box
      component="nav"
      sx={{ width: { lg: drawerWidth + 10 }, flexShrink: { lg: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        sx={{
          display: { xs: "none", lg: "block" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant={"permanent"}
        anchor="left"
        open
      >
        <MenuListPanel />
      </Drawer>
      <Drawer
        sx={{
          display: { xs: "block", lg: "none" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={handleToggleDrawer}
        variant={"temporary"}
        anchor="left"
        open={openSidebar}
      >
        <MenuListPanel />
      </Drawer>
    </Box>
  );
};

export default ResponsiveSidebar;
