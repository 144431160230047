import {
  Box,
  Button,
  Chip,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";

interface INewProductForm {
  input: any;
  categories: any;
  handleProductInputChange: any;
  handleFileUpload: any;
  handleProductSubmit: any;
  handleCategoriesChange: any;
}
const NewProductForm: React.FC<INewProductForm> = ({
  input,
  categories,
  handleProductInputChange,
  handleFileUpload,
  handleProductSubmit,
  handleCategoriesChange,
}) => (
  <Box
    component="form"
    sx={{
      maxWidth: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      "& .MuiTextField-root": { m: 1 },
    }}
    noValidate
    autoComplete="off"
  >
    <Box
      sx={{
        maxWidth: "100%",
        "& .MuiTextField-root": { width: { xs: "50ch", md: "80ch" } },
      }}
    >
      <TextField
        name="title"
        fullWidth
        id="outlined-product-name"
        label="Product Name"
        placeholder="Vintage Sweater for men, ages 30-35"
        onChange={handleProductInputChange}
      />
    </Box>
    <Box
      sx={{
        maxWidth: "100%",
        "& .MuiTextField-root": { width: { xs: "50ch", md: "80ch" } },
      }}
    >
      <TextField
        name="desc"
        fullWidth
        id="outlined-product-desc"
        label="Product Description"
        placeholder="Cosby sweater iphone artisan, squid trust fund photo booth twee blog shoreditch single-origin coffee aesthetic jean shorts messenger bag brooklyn butcher. Iphone fap banksy next level put a bird on it, letterpress photo booth thundercats biodiesel fanny pack etsy banh mi wayfarers. Sustainable four loko dreamcatcher, vegan single-origin coffee yr cardigan biodiesel williamsburg thundercats salvia master cleanse terry richardson tumblr mcsweeney’s. Lomo gluten-free salvia you probably haven’t heard of them portland. Mlkshk jean shorts carles, etsy artisan farm-to-table sartorial williamsburg marfa PBR leggings trust fund. Mcsweeney’s banh mi lo-fi mustache, fixie cosby sweater tumblr twee fanny pack. Thundercats food truck vegan, hoodie irony artisan beard synth."
        multiline
        maxRows={4}
        onChange={handleProductInputChange}
        defaultValue=""
      />
    </Box>
    <Box
      sx={{
        maxWidth: "100%",
        "& .MuiTextField-root": { width: { xs: "50ch", md: "80ch" } },
      }}
    >
      <TextField
        name="categories"
        id="outlined-product-categories"
        label="Product Categories"
        placeholder="women,casual,winter"
        helperText="Separate each tag by commas(,) to distinguish categories"
        onChange={handleCategoriesChange}
      />
    </Box>
    <Box sx={{ m: 1 }}>
      {categories &&
        categories.map((cat: string, index: number) => (
          <Chip
            key={cat + index}
            label={cat}
            color="primary"
            variant="outlined"
          />
        ))}
    </Box>
    <Box
      sx={{
        "& .MuiTextField-root": { width: { xs: "15ch", md: "25ch" } },
      }}
    >
      <TextField
        name="inStock"
        id="outlined-select-inStock"
        select
        label="In Stock"
        value="true"
        onChange={handleProductInputChange}
        helperText="Availability in stock"
      >
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </TextField>
      <TextField
        name="active"
        id="outlined-select-active"
        select
        label="Active"
        value="true"
        onChange={handleProductInputChange}
        helperText="Enable/disable product in store"
      >
        <MenuItem value="true">Yes</MenuItem>
        <MenuItem value="false">No</MenuItem>
      </TextField>

      <TextField
        name="price"
        id="outlined-product-price"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        label="Product Price"
        placeholder="20"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={handleProductInputChange}
        helperText="Unit price"
      />
    </Box>
    <Box>
      {input.img && (
        <>
          <div>Uploaded image:</div>
          <img
            height={"200px"}
            src={input.img}
            alt={`Uploaded for ${input.title}`}
          />
        </>
      )}
    </Box>

    <Stack spacing={2} direction="row">
      <Button variant="contained" component="label">
        Upload Image
        <input type="file" hidden onChange={handleFileUpload} />
      </Button>
      <Button onClick={handleProductSubmit} variant="contained">
        Create new product item
      </Button>
    </Stack>
  </Box>
);

export default NewProductForm;
