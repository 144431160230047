import DoneIcon from "@mui/icons-material/Done";
import {
  Chip,
  Container,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { INewProduct } from "interfaces";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductDispatcher, selectProductState } from "state";
import { fileUpload } from "utils/file.upload";
import NewProductForm from "components/Forms/NewProductForm";

export default function NewProduct() {
  const dispatch = useDispatch();
  const { error } = useSelector(selectProductState);

  const [message, setMessage] = useState<IUIMessage>({
    text: "",
    color: "success",
  });
  const [input, setInput] = useState<Partial<INewProduct>>({
    inStock: "true",
    active: "true",
  });
  const [categories, setCategories] = useState<string[]>([]);

  const handleProductInputChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });
  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = (e?.target?.files as FileList)[0] as File;
    setMessage({ text: "Uploading file", color: "info" });
    fileUpload(
      file,
      (downloadURL) => {
        setInput({ ...input, img: downloadURL });
      },
      (text, color) => setMessage({ text, color })
    );
  };
  const handleCategoriesChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setCategories(e.target.value.split(",").filter((c: string) => c.trim()));

  const handleProductSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setInput({ ...input, categories });
    setMessage({
      text: "New product added successfully!",
      color: "success",
    });
    addProductDispatcher(input, dispatch);
    error &&
      setMessage({ text: "Error: Couldn't update product", color: "error" });
  };

  useEffect(() => {
    if (error)
      setMessage({
        text: "An error occurred while creating product data",
        color: "error",
      });
  }, [error]);

  return (
    <Container>
      <Typography sx={{ m: 1 }} variant="h4">
        New Product
      </Typography>

      <Container component={Paper}>
        <NewProductForm
          input={input}
          categories={categories}
          handleProductInputChange={handleProductInputChange}
          handleFileUpload={handleFileUpload}
          handleProductSubmit={handleProductSubmit}
          handleCategoriesChange={handleCategoriesChange}
        />
        <Stack
          sx={{
            margin: "10px",
          }}
        >
          {message.text && (
            <Chip
              label={message.text}
              color={message.color}
              deleteIcon={<DoneIcon />}
            />
          )}
        </Stack>
        <Toolbar />
      </Container>
    </Container>
  );
}
