import { Publish } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Input,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { IUser } from "interfaces";
import React, { ChangeEventHandler, MouseEventHandler } from "react";

interface IUserUpdateFormProps {
  user: IUser;
  isFetching: boolean;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleUserSubmit: MouseEventHandler<HTMLButtonElement>;
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
}

const Column: React.FC = ({ children }) => (
  <Stack
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      "& .MuiTextField-root": { m: 1, width: "90%" },
    }}
  >
    {children}
  </Stack>
);

const Row: React.FC = ({ children }) => (
  <Stack
    sx={{
      display: "flex",
      flexDirection: { base: "column", md: "row" },
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
    }}
  >
    {children}
  </Stack>
);
const UserUpdateForm: React.FC<IUserUpdateFormProps> = ({
  user,
  isFetching,
  handleInputChange,
  handleUserSubmit,
  handleFileChange,
}) => (
  <Stack
    component="form"
    sx={{
      flexDirection: { base: "column" },
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      p: 2,
    }}
    noValidate
    autoComplete="off"
  >
    <Row>
      <Column>
        <TextField
          name="username"
          id="outlined-user-name"
          label="User Name"
          defaultValue={user.username}
          placeholder="johndoe"
          disabled
        />
        <TextField
          name="email"
          id="outlined-user-email"
          label="User Email"
          defaultValue={user.email}
          placeholder="johndoe@mail.com"
          helperText="Required during registration"
          onChange={handleInputChange}
          disabled={isFetching}
        />
        <Stack
          justifyContent="center"
          alignItems="flex-start"
          direction="row"
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: { xs: "14ch", sm: "14ch" },
            },
            maxWidth: "100%",
          }}
        >
          <TextField
            name="active"
            id="outlined-select-active"
            select
            label="Active"
            defaultValue={user.active}
            helperText="Enable/disable user account"
            onChange={handleInputChange}
            disabled={isFetching}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </TextField>
          <TextField
            name="isAdmin"
            id="outlined-select-isadmin"
            select
            label="isAdmin"
            defaultValue={user.isAdmin}
            helperText="User is also an administrator"
            onChange={handleInputChange}
            disabled={isFetching}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </TextField>
        </Stack>
      </Column>
      <Column>
        <TextField
          name="fullname"
          id="outlined-full-name"
          label="Full Name"
          defaultValue={user.fullname}
          placeholder="John Doe"
          onChange={handleInputChange}
          disabled={isFetching}
        />
        <TextField
          name="phone"
          id="outlined-user-phone"
          label="User Phone"
          defaultValue={user.phone}
          placeholder="+1 123 456 78"
          onChange={handleInputChange}
          disabled={isFetching}
        />
        <TextField
          name="address"
          id="outlined-user-address"
          label="User Address"
          multiline
          rows={4}
          defaultValue={user.address}
          placeholder="New York | USA"
          onChange={handleInputChange}
          disabled={isFetching}
        />
      </Column>
    </Row>
    <Row>
      <Column>
        <label htmlFor="image-button-file">
          <Button variant="text" component="span" disabled={isFetching}>
            <Input
              name="img"
              id="image-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isFetching}
            />
            <Avatar
              src={user.img}
              alt={user.username}
              sx={{ width: 240, height: 240 }}
              variant="square"
            />
          </Button>
        </label>
        <Button
          sx={{ width: 240 }}
          color="primary"
          variant="contained"
          onClick={handleUserSubmit}
          disabled={isFetching}
        >
          Update
          <Publish />
        </Button>
      </Column>
    </Row>
  </Stack>
);

export default UserUpdateForm;
