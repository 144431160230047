import {
  Box,
  Button,
  Container,
  Link,
  Stack,
  Paper,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
  deleteUserDispatcher,
  getUsersDispatcher,
  selectUsersList,
} from "state";
import UsersColumns from "./UsersColumns";

export default function Users() {
  const users = useSelector(selectUsersList);
  console.log("users list:", users);
  const [pageSize, setPageSize] = useState(8);
  const dispatch = useDispatch();
  useEffect(() => {
    getUsersDispatcher(dispatch);
  }, [dispatch]);
  const handleDelete = (id: string) => {
    deleteUserDispatcher(id, dispatch);
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ m: 1 }} variant="h4">
        Users list
      </Typography>
      <Stack
        component={Paper}
        sx={{
          p: 2,
          width: "100%",
        }}
      >
        <div style={{ flexGrow: 0 }}>
          <DataGrid
            autoHeight
            rows={users.filter((user) => user._id && user)}
            getRowId={(row) => row._id}
            disableSelectionOnClick
            columns={UsersColumns(handleDelete)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 8, 12, 20]}
            pagination
          />
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 1,
          }}
        >
          <Link to={"/user/new"} component={RouterLink} underline="none">
            <Button color="primary" variant="contained">
              Create New User
            </Button>
          </Link>
        </Box>
      </Stack>
    </Container>
  );
}
