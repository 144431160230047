import { createSlice } from "@reduxjs/toolkit";
import { IUserState } from "interfaces";
import {
  loginStartReducer,
  loginSuccessReducer,
  loginFailureReducer,
  getUsersFailureReducer,
  getUsersStartReducer,
  getUsersSuccessReducer,
  deleteUserStartReducer,
  deleteUserSuccessReducer,
  deleteUserFailureReducer,
  logoutStartReducer,
  logoutSuccessReducer,
  logoutFailureReducer,
  updateUserFailureReducer,
  updateUserStartReducer,
  updateUserSuccessReducer,
  registerUserStartReducer,
  registerUserSuccessReducer,
  registerUserFailureReducer,
} from "state/reducers";

const initialState: IUserState = {
  usersList: [],
  currentUser: null,
  userStatus: "neverLogged",
  isFetching: false,
  error: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: loginStartReducer,
    loginSuccess: loginSuccessReducer,
    loginFailure: loginFailureReducer,
    logoutStart: logoutStartReducer,
    logoutSuccess: logoutSuccessReducer,
    logoutFailure: logoutFailureReducer,
    getUsersStart: getUsersStartReducer,
    getUsersSuccess: getUsersSuccessReducer,
    getUsersFailure: getUsersFailureReducer,
    deleteUserStart: deleteUserStartReducer,
    deleteUserSuccess: deleteUserSuccessReducer,
    deleteUserFailure: deleteUserFailureReducer,
    updateUserStart: updateUserStartReducer,
    updateUserSuccess: updateUserSuccessReducer,
    updateUserFailure: updateUserFailureReducer,
    registerUserStart: registerUserStartReducer,
    registerUserSuccess: registerUserSuccessReducer,
    registerUserFailure: registerUserFailureReducer,
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logoutStart,
  logoutSuccess,
  logoutFailure,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  registerUserStart,
  registerUserSuccess,
  registerUserFailure,
} = userSlice.actions;
// export const { reducer: userReducer } = userSlice;
