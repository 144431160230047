import axios from "axios";
import { apiServerHost } from "config";
import { INewUser, IUser } from "interfaces";

export const postUserLogin = (user: object) =>
  axios.post(`${apiServerHost}/auth/login`, user, { withCredentials: true });

export const logoutUser = () =>
  axios.post(`${apiServerHost}/auth/logout`, { withCredentials: true });

export const registerUser = (user: INewUser) =>
  axios.post<IUser>(`${apiServerHost}/auth/register`, user, {
    withCredentials: true,
  });
