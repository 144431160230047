import { IIncome, IIndicatorData, IMainPanelIndicators } from "interfaces";

export const initialIndicators: IMainPanelIndicators = {
  Revenue: {
    quantity: 2415,
    trend: -11.4,
    timeframe: "month (dummy)",
  },
  Cost: {
    quantity: 4415,
    trend: -1.4,
    timeframe: "month (dummy)",
  },
  Sales: {
    quantity: 2225,
    trend: 2.4,
    timeframe: "month (dummy)",
  },
};

export const getRevenueIndicator = (
  income: IIncome[],
  timeframe: string
): IIndicatorData => {
  const revPerc = (income[0].total * 100) / (income[1].total - 100);
  const revPrevMonth = income[1].total;
  return {
    quantity: revPrevMonth,
    trend: revPerc,
    timeframe,
  };
};
