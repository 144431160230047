import { Container, Paper, Typography } from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

export default function GenericChart({
  title,
  rawData,
  dataKey,
  grid = false,
}) {
  return (
    <Paper sx={{ p: 2, alignItems: "stretch" }} width={"99%"} height={"100%"}>
      <Typography variant="h6" component="div">
        {title}
      </Typography>
      <Container>
        <ResponsiveContainer width="100%" aspect={4 / 1}>
          <LineChart data={rawData}>
            <XAxis dataKey="name" stroke="#5550bd" />
            <Line type="monotone" dataKey={dataKey} stroke="#5550bd" />
            <Tooltip />
            {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />}
          </LineChart>
        </ResponsiveContainer>
      </Container>
    </Paper>
  );
}
