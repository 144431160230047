import { createSlice } from "@reduxjs/toolkit";
import { IOrderState } from "interfaces";
import {
  getOrderStartReducer,
  getOrderFailureReducer,
  getOrderSuccessReducer,
  deleteOrderFailureReducer,
  deleteOrderStartReducer,
  deleteOrderSuccessReducer,
  updateOrderStartReducer,
  updateOrderSuccessReducer,
  updateOrderFailureReducer,
} from "state/reducers";

const initialState: IOrderState = {
  orders: [],
  isFetching: false,
  error: false,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getOrderStart: getOrderStartReducer,
    getOrderSuccess: getOrderSuccessReducer,
    getOrderFailure: getOrderFailureReducer,
    deleteOrderStart: deleteOrderStartReducer,
    deleteOrderSuccess: deleteOrderSuccessReducer,
    deleteOrderFailure: deleteOrderFailureReducer,
    updateOrderStart: updateOrderStartReducer,
    updateOrderSuccess: updateOrderSuccessReducer,
    updateOrderFailure: updateOrderFailureReducer,
  },
});

export const {
  getOrderStart,
  getOrderSuccess,
  getOrderFailure,
  deleteOrderStart,
  deleteOrderSuccess,
  deleteOrderFailure,
  updateOrderStart,
  updateOrderSuccess,
  updateOrderFailure,
} = orderSlice.actions;
// export const { reducer: OrderReducer } = OrderSlice;
