import { Visibility } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Button,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getNewUsers } from "services/user.requests";
import { IUser } from "interfaces";

const UsersSummary = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await getNewUsers();
        setUsers(res.data || []);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, []);
  return (
    <Stack component={Paper} sx={{ p: 2, width: "100%" }}>
      {!users.length ? (
        <Typography variant="h6" noWrap component="span">
          No users fetched
        </Typography>
      ) : (
        <>
          <Typography variant="h6" noWrap component="span">
            New Join Members
          </Typography>
          <TableContainer sx={{ minWidth: { xs: "100%", md: "auto" } }}>
            <Table aria-label="Users summary table">
              <TableHead>
                <TableRow>
                  <TableCell>ID Photo</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>User data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow
                    key={user._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {user.img ? (
                        <Avatar src={user.img} />
                      ) : (
                        <AccountCircleIcon />
                      )}
                    </TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>
                      <Link
                        to={"/user/" + user._id}
                        component={RouterLink}
                        underline="none"
                      >
                        <Button variant="outlined">
                          <Visibility /> &nbsp;Display
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Stack>
  );
};

export default UsersSummary;
