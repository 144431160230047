import axios from "axios";
import { apiServerHost } from "config";
import { IUser, IUserStats } from "interfaces";

export const getUserById = (id: string) =>
  axios.get<IUser>(`${apiServerHost}/users/find/` + id, {
    withCredentials: true,
  });

export const getNewUsers = () =>
  axios.get<IUser[]>(`${apiServerHost}/users/?new=true`, {
    withCredentials: true,
  });

export const getUsers = () =>
  axios.get<IUser[]>(`${apiServerHost}/users`, { withCredentials: true });

export const getUserStats = () =>
  axios.get<IUserStats[]>(`${apiServerHost}/users/stats`, {
    withCredentials: true,
  });

export const deleteUserById = (id: string) =>
  axios.delete<string>(`${apiServerHost}/users/` + id, {
    withCredentials: true,
  });

export const updateUserById = (id: string, user: Partial<IUser>) =>
  axios.put<IUser>(`${apiServerHost}/users/` + id, user, {
    withCredentials: true,
  });
