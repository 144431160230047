import {
  AccountCircle,
  Language,
  Notifications,
  Settings,
} from "@mui/icons-material";
import { Badge, IconButton, Link, Menu, MenuItem } from "@mui/material";
import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoggedUserId } from "state";
import { MenuActions } from "constant";

interface MobileMenuProps {
  mobileMoreAnchorEl: any;
  isMobileMenuOpen: any;
  handleMobileMenuClose: any;
  handleProfileMenuOpen: any;
}
const MobileMenu: React.FC<MobileMenuProps> = ({
  mobileMoreAnchorEl,
  isMobileMenuOpen,
  handleMobileMenuClose,
  handleProfileMenuOpen,
}) => {
  const mobileMenuId = "primary-account-menu-mobile";
  const idUser = useSelector(selectLoggedUserId);
  return (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <Notifications />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <Language />
          </Badge>
        </IconButton>
        <p>Language</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <Settings />
          </Badge>
        </IconButton>
        <p>Settings</p>
      </MenuItem>
      <Link component={RouterLink} to={"/user/" + idUser} underline="none">
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Link>
      <MenuItem onClick={() => handleMobileMenuClose(MenuActions.LOGOUT)}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <LogoutIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );
};

export default MobileMenu;
