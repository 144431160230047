import LogoutIcon from "@mui/icons-material/Logout";
import { Menu, MenuItem } from "@mui/material";
import { MenuActions } from "constant";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

interface PrimaryMenuProps {
  anchorEl: any;
  isMenuOpen: any;
  handleMenuClose: any;
}
const PrimaryMenu: React.FC<PrimaryMenuProps> = ({
  anchorEl,
  isMenuOpen,
  handleMenuClose,
}) => {
  const menuId = "primary-account-menu";
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => handleMenuClose(MenuActions.ACCOUNT)}>
        <AccountCircleIcon />
        &nbsp;Profile
      </MenuItem>
      <MenuItem onClick={() => handleMenuClose(MenuActions.LOGOUT)}>
        <LogoutIcon />
        &nbsp;Logout
      </MenuItem>
    </Menu>
  );
};
export default PrimaryMenu;
