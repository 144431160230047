import { PayloadAction } from "@reduxjs/toolkit";
import {
  IUser,
  IUserState,
  TDeleteUserPayload,
  TGetUsersListPayload,
  TUserPayload,
} from "interfaces";

// LOGIN Reducers
export const loginStartReducer = (state: IUserState) => {
  state.isFetching = true;
  state.error = false;
};

export const loginSuccessReducer = (
  state: IUserState,
  action: PayloadAction<TUserPayload>
) => {
  state.currentUser = action.payload;
  state.isFetching = false;
};

export const loginFailureReducer = (state: IUserState) => {
  state.isFetching = false;
  state.error = true;
};

// LOGOUT Reducers
export const logoutStartReducer = (state: IUserState) => {
  state.isFetching = true;
  state.error = false;
};

export const logoutSuccessReducer = (state: IUserState) => {
  state.currentUser = null;
  state.isFetching = false;
};

export const logoutFailureReducer = (state: IUserState) => {
  state.isFetching = false;
  state.error = true;
};

// GET Reducers
export const getUsersStartReducer = (state: IUserState) => {
  state.isFetching = true;
  state.error = false;
};

export const getUsersSuccessReducer = (
  state: IUserState,
  action: PayloadAction<TGetUsersListPayload>
) => {
  state.usersList = action.payload;
  state.isFetching = false;
  state.error = false;
};

export const getUsersFailureReducer = (state: IUserState) => {
  state.isFetching = false;
  state.error = true;
};

// DELETE Reducers
export const deleteUserStartReducer = (state: IUserState) => {
  state.isFetching = true;
  state.error = false;
};

export const deleteUserSuccessReducer = (
  state: IUserState,
  action: PayloadAction<TDeleteUserPayload>
) => {
  state.usersList.splice(
    state.usersList.findIndex((item) => item._id === action.payload.id),
    1
  );
  state.isFetching = false;
  state.error = false;
};

export const deleteUserFailureReducer = (state: IUserState) => {
  state.isFetching = false;
  state.error = true;
};

// UPDATE Reducers
export const updateUserStartReducer = (state: IUserState) => {
  state.isFetching = true;
  state.error = false;
};

export const updateUserSuccessReducer = (
  state: IUserState,
  action: PayloadAction<IUser>
) => {
  const uptdUser = action.payload;
  const usrIdx = state.usersList.findIndex((item) => item._id === uptdUser._id);
  state.usersList[usrIdx] = uptdUser;
  state.isFetching = false;
  state.error = false;
};

export const updateUserFailureReducer = (state: IUserState) => {
  state.isFetching = false;
  state.error = true;
};

// Create Reducers
export const registerUserStartReducer = (state: IUserState) => {
  state.isFetching = true;
  state.error = false;
};

export const registerUserSuccessReducer = (
  state: IUserState,
  action: PayloadAction<IUser>
) => {
  state.usersList.push(action.payload);
  state.isFetching = false;
  state.error = false;
};

export const registerUserFailureReducer = (state: IUserState) => {
  state.isFetching = false;
  state.error = true;
};
