import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Chip,
  Container,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { INewUser } from "interfaces";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUserDispatcher, selectUserState } from "state";
import { fileUpload } from "utils/file.upload";
import NewUserForm from "components/Forms/NewUserForm";
import { Stack } from "@mui/material";

const initialEmptyUser: INewUser = {
  username: "",
  fullname: "",
  address: "",
  phone: "",
  email: "",
  img: "",
  password: "",
  isAdmin: false,
  active: true,
};

export default function NewUser() {
  // Hooks
  const dispatch = useDispatch();
  const { error } = useSelector(selectUserState);

  const [message, setMessage] = useState<IUIMessage>({
    text: "",
    color: "info",
  });
  const [input, setInput] = useState<INewUser>(initialEmptyUser);
  const [passwordCheck, setPasswordCheck] = useState({
    password: "",
    repeat: "",
  });

  // Handlers
  const handleUserInputChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setInput({
      ...input,
      [e.target.name]:
        //Convert to boolean for these fields
        ["isAdmin", "active"].indexOf(e.target.name) !== -1
          ? JSON.parse(e.target.value)
          : e.target.value,
    });

  const handlePasswordCheck: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) =>
    setPasswordCheck({
      ...passwordCheck,
      [e.target.name]: e.target.value,
    });

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = (e?.target?.files as FileList)[0] as File;
    setMessage({ text: "Uploading file", color: "info" });
    fileUpload(
      file,
      (downloadURL) => {
        setInput({ ...input, img: downloadURL });
        setMessage({ text: "File upload complete", color: "info" });
      },
      (text, color) => setMessage({ text, color })
    );
  };

  const handleNewUserSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setMessage({ text: "User update successful!", color: "success" });
    registerUserDispatcher(input, dispatch);

    error &&
      setMessage({ text: "Error: Couldn't update user", color: "error" });
  };

  // Re-render conditions
  useEffect(() => {
    if (error)
      setMessage({
        text: "An error occurred while creating user data",
        color: "error",
      });
  }, [error]);
  useEffect(() => {
    // Only set password inside the input state once they match
    if (passwordCheck.password === passwordCheck.repeat)
      setInput((input) => ({
        ...input,
        password: passwordCheck.password,
      }));
  }, [passwordCheck]);

  return (
    <Container>
      <Typography sx={{ m: 1 }} variant="h4">
        New User
      </Typography>

      <Box
        component={Paper}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        p={4}
      >
        <NewUserForm
          input={input}
          passwordCheck={passwordCheck}
          handlePasswordCheck={handlePasswordCheck}
          handleFileChange={handleFileUpload}
          handleNewUserSubmit={handleNewUserSubmit}
          handleUserInputChange={handleUserInputChange}
        />
        <Stack
          justifyContent={"center"}
          sx={{
            margin: "10px",
          }}
        >
          {message.text && (
            <Chip
              sx={{ justifySelf: "center" }}
              label={message.text}
              color={message.color}
              deleteIcon={<DoneIcon />}
            />
          )}
        </Stack>
      </Box>
      <Toolbar />
    </Container>
  );
}
