import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IOrder } from "interfaces/orders";
import { useEffect, useState } from "react";
import { getOrders } from "services/order.request";
import TransactionRow from "./TransactionRow";

const OrderSummary = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const res = await getOrders();
        setOrders(
          res.data.slice(0, res.data.length < 5 ? res.data.length : 5) || []
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchOrders();
  }, []);
  return (
    <Stack component={Paper} sx={{ p: 2, width: "100%" }}>
      {!orders.length ? (
        <Typography variant="h6" noWrap component="span">
          No orders fetched
        </Typography>
      ) : (
        <>
          <Typography variant="h6" noWrap component="span">
            Latest transactions
          </Typography>
          <TableContainer sx={{ minWidth: { xs: "100%" } }}>
            <Table aria-label="Orders summary table">
              <TableHead>
                <TableRow>
                  <TableCell>Customer</TableCell>
                  <TableCell sx={{ display: { xs: "none", xl: "table-cell" } }}>
                    Date
                  </TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order, index) => (
                  <TransactionRow key={order._id} order={order} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Stack>
  );
};

export default OrderSummary;
