import { Container, Paper, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrderDispatcher,
  getOrdersDispatcher,
  selectOrders,
} from "state";
import OrdersColumns from "./OrdersColumns";

interface IOrdersProps {}
const Orders = (props: IOrdersProps) => {
  const orders = useSelector(selectOrders);
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(8);

  const handleDelete = (id: string) => {
    deleteOrderDispatcher(id, dispatch);
  };

  useEffect(() => {
    getOrdersDispatcher(dispatch);
  }, [dispatch]);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography sx={{ m: 1 }} variant="h4">
        Orders list
      </Typography>
      <Stack
        component={Paper}
        sx={{
          p: 2,
        }}
      >
        <div style={{ flexGrow: 0 }}>
          <DataGrid
            autoHeight
            density="comfortable"
            rowHeight={52}
            rows={orders}
            getRowId={(row) => row._id}
            disableSelectionOnClick
            columns={OrdersColumns(handleDelete)}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 8, 12, 20]}
            pagination
          />
        </div>
      </Stack>
    </Container>
  );
};

export default Orders;
