import { EMonths } from "constant";

export const initialUsersStats = [
  { name: EMonths.Jan, "Active Users": 0 },
  { name: EMonths.Feb, "Active Users": 0 },
  { name: EMonths.Mar, "Active Users": 0 },
  { name: EMonths.Apr, "Active Users": 0 },
  { name: EMonths.May, "Active Users": 0 },
  { name: EMonths.Jun, "Active Users": 0 },
  { name: EMonths.Jul, "Active Users": 0 },
  { name: EMonths.Agu, "Active Users": 0 },
  { name: EMonths.Sep, "Active Users": 0 },
  { name: EMonths.Oct, "Active Users": 0 },
  { name: EMonths.Nov, "Active Users": 0 },
  { name: EMonths.Dec, "Active Users": 0 },
];
