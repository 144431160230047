import { Publish } from "@mui/icons-material";
import {
  Stack,
  Paper,
  TextField,
  MenuItem,
  Button,
  Input,
  Avatar,
} from "@mui/material";
import { IProduct } from "interfaces";
import React, { ChangeEventHandler, MouseEventHandler } from "react";

interface IProductUpdateFormProps {
  product: IProduct;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleProductSubmit: MouseEventHandler<HTMLButtonElement>;
  handleImageChange: ChangeEventHandler<HTMLInputElement>;
}
const ProductUpdateForm: React.FC<IProductUpdateFormProps> = ({
  product,
  handleInputChange,
  handleProductSubmit,
  handleImageChange,
}) => (
  <Stack component={Paper} sx={{ p: 2 }} justifyContent="center">
    <Stack
      component="form"
      direction={{ xs: "column", lg: "row" }}
      justifyContent="center"
      alignItems="center"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
      spacing={3}
    >
      <Stack sx={{ maxWidth: "100%" }}>
        <Stack direction={"row"}>
          <TextField
            name="title"
            id="outlined-product-name"
            label="Product Name"
            defaultValue={product.title}
            onChange={handleInputChange}
          />
          <TextField
            name="price"
            id="outlined-product-price"
            label="Product Price"
            defaultValue={product.price}
            onChange={handleInputChange}
          />
        </Stack>
        <Stack direction={"row"}>
          <TextField
            name="inStock"
            id="outlined-select-inStock"
            select
            label="In Stock"
            defaultValue={product.inStock}
            helperText="Please select your in-Stock status"
            onChange={handleInputChange}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </TextField>
          <TextField
            name="active"
            id="outlined-select-active"
            select
            label="Active"
            defaultValue={product.active}
            helperText="Please select the Active product status"
            onChange={handleInputChange}
          >
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </TextField>
        </Stack>
        <Stack>
          <TextField
            name="desc"
            sx={{
              minWidth: "97%",
            }}
            multiline
            maxRows={4}
            id="outlined-product-desc"
            label="Product Description"
            defaultValue={product.desc}
            onChange={handleInputChange}
          />
        </Stack>
      </Stack>

      <Stack alignItems="center" justifyContent="center">
        <label htmlFor="image-button-file">
          <Button variant="text" component="span">
            <Input
              name="file"
              id="image-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <Avatar
              src={product.img}
              alt={product.title}
              sx={{ width: 240, height: 240 }}
              variant="square"
            />
          </Button>
        </label>

        <Button
          sx={{ width: 240 }}
          color="primary"
          variant="contained"
          onClick={handleProductSubmit}
        >
          Update
          <Publish />
        </Button>
      </Stack>
    </Stack>
  </Stack>
);

export default ProductUpdateForm;
