import { IUser } from "interfaces";
import { Dispatch } from "redux";
import { INewUser } from "../../interfaces/user";
import {
  registerUser,
  deleteUserById,
  getUsers,
  logoutUser,
  postUserLogin,
  updateUserById,
} from "services";
import {
  registerUserFailure,
  registerUserStart,
  registerUserSuccess,
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  getUsersFailure,
  getUsersStart,
  getUsersSuccess,
  loginFailure,
  loginStart,
  loginSuccess,
  logoutFailure,
  logoutStart,
  logoutSuccess,
  updateUserFailure,
  updateUserStart,
  updateUserSuccess,
} from "state/slices";

export const loginDispatcher = async (
  dispatch: Dispatch,
  user: Partial<IUser>
) => {
  dispatch(loginStart());
  try {
    const { data: userData } = await postUserLogin(user);
    dispatch(loginSuccess(userData));
  } catch (error) {
    dispatch(loginFailure());
  }
};

export const registerUserDispatcher = async (
  user: INewUser,
  dispatch: Dispatch
) => {
  dispatch(registerUserStart());
  try {
    const { data: userData } = await registerUser(user);
    dispatch(registerUserSuccess(userData));
  } catch (error) {
    dispatch(registerUserFailure());
  }
};

export const logoutDispatcher = async (dispatch: Dispatch) => {
  dispatch(logoutStart());
  try {
    await logoutUser();
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutFailure());
  }
};

export const getUsersDispatcher = async (dispatch: Dispatch) => {
  dispatch(getUsersStart());
  try {
    const { data: userData } = await getUsers();
    dispatch(getUsersSuccess(userData));
  } catch (error) {
    dispatch(getUsersFailure());
  }
};

export const deleteUserDispatcher = async (id: string, dispatch: Dispatch) => {
  dispatch(deleteUserStart());
  try {
    await deleteUserById(id);
    dispatch(deleteUserSuccess({ id }));
  } catch (error) {
    dispatch(deleteUserFailure());
  }
};

export const updateUserDispatcher = async (
  userId: string,
  user: Partial<IUser>,
  dispatch: Dispatch
) => {
  dispatch(updateUserStart());
  try {
    const { data: userData } = await updateUserById(userId, user);
    dispatch(updateUserSuccess(userData));
  } catch (error) {
    dispatch(updateUserFailure());
  }
};
