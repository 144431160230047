import { PayloadAction } from "@reduxjs/toolkit";
import {
  IAddProductPayload,
  IProductState,
  TDeleteProductPayload,
  TGetProductPayload,
  TUpdateProductPayload,
} from "interfaces";

// GET product
export const getProductStartReducer = (state: IProductState) => {
  state.isFetching = true;
  state.error = false;
};

export const getProductSuccessReducer = (
  state: IProductState,
  action: PayloadAction<TGetProductPayload>
) => {
  state.products = action.payload;
  state.isFetching = false;
  state.error = false;
};

export const getProductFailureReducer = (state: IProductState) => {
  state.isFetching = false;
  state.error = true;
};

// DELETE product
export const deleteProductStartReducer = (state: IProductState) => {
  state.isFetching = true;
  state.error = false;
};

export const deleteProductSuccessReducer = (
  state: IProductState,
  action: PayloadAction<TDeleteProductPayload>
) => {
  state.products.splice(
    state.products.findIndex((item) => item._id === action.payload.id),
    1
  );
  state.isFetching = false;
  state.error = false;
};

export const deleteProductFailureReducer = (state: IProductState) => {
  state.isFetching = false;
  state.error = true;
};

// UPDATE(put) product data
export const updateProductStartReducer = (state: IProductState) => {
  state.isFetching = true;
  state.error = false;
};

export const updateProductSuccessReducer = (
  state: IProductState,
  action: PayloadAction<TUpdateProductPayload>
) => {
  state.products[
    state.products.findIndex((item) => item._id === action.payload.id)
  ] = action.payload.product;
  state.isFetching = false;
  state.error = false;
};

export const updateProductFailureReducer = (state: IProductState) => {
  state.isFetching = false;
  state.error = true;
};

// ADD(post) new product
export const addProductStartReducer = (state: IProductState) => {
  state.isFetching = true;
  state.error = false;
};

export const addProductSuccessReducer = (
  state: IProductState,
  action: PayloadAction<IAddProductPayload>
) => {
  state.products.push(action.payload.product);
  state.isFetching = false;
  state.error = false;
};

export const addProductFailureReducer = (state: IProductState) => {
  state.isFetching = false;
  state.error = true;
};
