import { INewProduct } from "interfaces";
import { IProduct } from "interfaces/products";
import { Dispatch } from "redux";
import {
  addProduct,
  deleteProductById,
  getProducts,
  updateProductById,
} from "services/product.requests";
import {
  addProductFailure,
  addProductStart,
  addProductSuccess,
  deleteProductFailure,
  deleteProductStart,
  deleteProductSuccess,
  getProductFailure,
  getProductStart,
  getProductSuccess,
  updateProductFailure,
  updateProductStart,
  updateProductSuccess,
} from "state/slices";

export const getProductsDispatcher = async (dispatch: Dispatch) => {
  dispatch(getProductStart());
  try {
    const { data: productData } = await getProducts();
    dispatch(getProductSuccess(productData));
  } catch (error) {
    dispatch(getProductFailure());
  }
};

export const deleteProductDispatcher = async (
  id: string,
  dispatch: Dispatch
) => {
  dispatch(deleteProductStart());
  try {
    await deleteProductById(id);
    dispatch(deleteProductSuccess({ id }));
  } catch (error) {
    dispatch(deleteProductFailure());
  }
};

export const updateProductDispatcher = async (
  productId: string,
  updateProduct: Partial<IProduct>,
  dispatch: Dispatch
) => {
  dispatch(updateProductStart());
  try {
    const { data: product } = await updateProductById(productId, updateProduct);
    dispatch(updateProductSuccess({ id: productId, product }));
  } catch (error) {
    dispatch(updateProductFailure());
  }
};

export const addProductDispatcher = async (
  newProduct: Partial<INewProduct>,
  dispatch: Dispatch
) => {
  dispatch(addProductStart());
  try {
    const { data: product } = await addProduct(newProduct);
    dispatch(addProductSuccess({ product }));
  } catch (error) {
    dispatch(addProductFailure());
  }
};
