import DoneIcon from "@mui/icons-material/Done";
import {
  Avatar,
  Box,
  Chip,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import GenericChart from "components/Chart/Chart";
import { monthsMap } from "constant/index";
import { IProduct } from "interfaces";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getOrdersIncomeByProductId } from "services/order.request";
import {
  selectProductById,
  selectProductState,
  updateProductDispatcher,
} from "state";
import { IProductStats } from "utils/products.plot";
import { fileUpload } from "utils/file.upload";
import ProductUpdateForm from "components/Forms/ProductUpdateForm";

interface TableSummaryProps {
  product: any;
}
const TableSummary: React.FC<TableSummaryProps> = ({ product }) => {
  return (
    <Box>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={product.img} alt={product.title} />
          </ListItemAvatar>
          <ListItemText primary={product.title} />
        </ListItem>
      </List>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <ListItemText primary="id:" />
              </TableCell>
              <TableCell>
                <ListItemText primary={product._id} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ListItemText primary="sales:" />
              </TableCell>
              <TableCell>
                <ListItemText primary="5123" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ListItemText primary="active:" />
              </TableCell>
              <TableCell>
                <ListItemText primary={product.active ? "yes" : "no"} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ListItemText primary="in stock:" />
              </TableCell>
              <TableCell>
                <ListItemText primary={product.inStock ? "yes" : "no"} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default function EditProduct() {
  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const productId = location.pathname.split("/")[2];
  const product = useSelector(selectProductById(productId));
  const { error } = useSelector(selectProductState);

  // Component State
  const [message, setMessage] = useState<IUIMessage>({
    text: "",
    color: "info",
  });
  const [productStats, setProductStats] = useState<IProductStats[] | null>(
    null
  );
  const [input, setInput] = useState<Partial<IProduct>>({});

  // Handlers(form)
  const handleProductInputChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });
  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = (e?.target?.files as FileList)[0] as File;
    setMessage({ text: "Uploading file", color: "info" });
    fileUpload(
      file as File,
      (downloadURL) => {
        setInput({ ...input, img: downloadURL });
      },
      (text, color) => setMessage({ text, color })
    );
  };
  const handleProductSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setMessage({ text: "User update successful!", color: "success" });
    updateProductDispatcher(productId, input, dispatch);
    error &&
      setMessage({ text: "Error: Couldn't update product", color: "error" });
  };

  // Re-renders
  useEffect(() => {
    if (error)
      setMessage({
        text: "An en error occurred while updating product data",
        color: "error",
      });
  }, [error]);
  useEffect(() => {
    const getStats = async () => {
      try {
        const res = await getOrdersIncomeByProductId(productId);
        if (res.status >= 200 && res.status < 300) {
          const newStats: IProductStats[] = res.data.map((stat) => ({
            name: monthsMap[stat._id],
            sales: stat.total,
          }));
          setProductStats(newStats);
        } else throw Error(`Error ${res.status}: ${res.statusText}`);
      } catch (error) {
        navigate(`/notfound?e=${JSON.stringify(error)}`);
        console.dir(error);
      }
    };
    getStats();
  }, [productId, navigate]);
  useEffect(() => {
    if (!product && !productStats) {
      navigate(`/notfound?e=${JSON.stringify(Error("400: Unknown error"))}`);
    }
  }, [product, productStats, navigate]);

  return (
    <>
      {product && productStats && (
        <>
          <Container>
            <Typography sx={{ m: 1 }} variant="h4">
              Product
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Stack
                component={Paper}
                justifyContent="center"
                direction={{ xs: "column", lg: "row" }}
                sx={{
                  marginBottom: 2,
                  p: 2,
                }}
                spacing={3}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <GenericChart
                    rawData={productStats}
                    dataKey="sales"
                    title="Sales Performance ($USD)"
                  />
                </Box>
                <TableSummary product={product} />
              </Stack>
              <ProductUpdateForm
                product={product}
                handleInputChange={handleProductInputChange}
                handleProductSubmit={handleProductSubmit}
                handleImageChange={handleFileUpload}
              />
              <Stack
                justifyContent={"center"}
                sx={{
                  margin: "10px",
                }}
              >
                {message.text && (
                  <Chip
                    sx={{ justifySelf: "center" }}
                    label={message.text}
                    color={message.color}
                    deleteIcon={<DoneIcon />}
                  />
                )}
              </Stack>
            </Box>
          </Container>
          <Toolbar />
        </>
      )}
    </>
  );
}
