import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { truncate } from "lodash";
import { Link as RouterLink } from "react-router-dom";

type TDeleteHandler = (id: string) => void;
const ProductsColumns = (handleDelete: TDeleteHandler) => [
  { field: "_id", headerName: "ID", width: 220 },
  {
    field: "product",
    headerName: "Product",
    width: 360,
    renderCell: (params: any) => (
      <List component={Stack} direction="row">
        <ListItem disablePadding>
          <ListItemAvatar>
            <Avatar alt={params.row.title} src={params.row.img} />
          </ListItemAvatar>
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            secondary={truncate(params.row.title, { length: 40 })}
          />
        </ListItem>
      </List>
    ),
  },
  { field: "inStock", headerName: "Stock", width: 100 },
  { field: "active", headerName: "Enabled(Active)", width: 140 },
  {
    field: "price",
    headerName: "Price",
    width: 100,
  },
  {
    field: "action",
    headerName: "Action",
    width: 150,
    renderCell: (params: any) => (
      <ButtonGroup variant="text" aria-label="text button group">
        <Button
          to={"/product/" + params.row._id}
          component={RouterLink}
          variant="text"
          color="success"
          size="medium"
        >
          <EditIcon />
        </Button>
        <Button
          size="medium"
          variant="text"
          color="error"
          onClick={() => handleDelete(params.row._id)}
        >
          <DeleteIcon />
        </Button>
      </ButtonGroup>
    ),
  },
];

export default ProductsColumns;
