import {
  // Notifications,
  // Language,
  Settings,
  AccountCircle,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  //  Badge,
  Avatar,
  Tooltip,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";

interface ButtonBarProps {
  user: any;
  handleProfileMenuOpen: any;
  handleMobileMenuOpen: any;
}

const ButtonBar: React.FC<ButtonBarProps> = ({
  user,
  handleProfileMenuOpen,
  handleMobileMenuOpen,
}) => {
  const menuId = "primary-account-menu";
  const mobileMenuId = "primary-account-menu-mobile";
  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <Notifications />
          </Badge>
        </IconButton>
        <IconButton size="large" aria-label="show notif." color="inherit">
          <Badge badgeContent={17} color="error">
            <Language />
          </Badge>
        </IconButton> */}
        <IconButton size="large" aria-label="user settings" color="inherit">
          <Settings />
        </IconButton>
        <Tooltip title="Open settings">
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            sx={{ p: 0 }}
          >
            {user.img ? (
              <Avatar alt="Remy Sharp" src={user.img} />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default ButtonBar;
