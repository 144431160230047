// import moment from "moment";
import { Box, Paper, Typography } from "@mui/material";
import { Chart, registerables } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
Chart.register(...registerables);
type Props = {
  rawData: any;
  title: string;
  dataKey: string;
  grid: boolean;
};

const LineChart = ({ title, rawData, dataKey, grid = false }: Props) => {
  const currentMonthIdx = new Date().getMonth();
  const idx = {
    start: currentMonthIdx - 12 + 1,
    end: currentMonthIdx + 1,
  };
  const rawRegs = Object.values(rawData);
  const userRegistrations = [
    ...rawRegs.slice(idx.start),
    ...rawRegs.slice(0, idx.end),
  ];

  //   coinHistory?.data?.history.forEach((historyItem: any) => {
  //     userRegistrations.unshift(historyItem.price);
  //     userMonthTimestamp.unshift(
  //       moment.unix(historyItem.timestamp).format("YYYY-MM-DD")
  //     );
  //   });

  const data = {
    labels: userRegistrations.map((reg: any) => reg.name),
    datasets: [
      {
        label: "Active Users",
        data: userRegistrations.map((reg: any) => reg["Active Users"]),
        fill: false,
        backgroundColor: "#a9d7f5",
        borderColor: "#0071bd",
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "#02242c",
          lineWidth: 0.1,
        },
      },
      x: {
        grid: {
          color: "#02242c",
          lineWidth: 0.1,
        },
      },
    },
  };

  return (
    <Paper sx={{ p: 2, alignItems: "stretch" }}>
      <Box>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </Box>
      <Line data={data} options={options} />
    </Paper>
  );
};

export default LineChart;
