export enum EMonths {
  "Jan" = "Jan",
  "Feb" = "Feb",
  "Mar" = "Mar",
  "Apr" = "Apr",
  "May" = "May",
  "Jun" = "Jun",
  "Jul" = "Jul",
  "Agu" = "Agu",
  "Sep" = "Sep",
  "Oct" = "Oct",
  "Nov" = "Nov",
  "Dec" = "Dec",
}
export const monthsMap: EMonths[] = [
  EMonths.Jan,
  EMonths.Feb,
  EMonths.Mar,
  EMonths.Apr,
  EMonths.May,
  EMonths.Jun,
  EMonths.Jul,
  EMonths.Agu,
  EMonths.Sep,
  EMonths.Oct,
  EMonths.Nov,
  EMonths.Dec,
];

export enum MenuActions {
  LOGOUT,
  USERS,
  ACCOUNT,
}
