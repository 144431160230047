import { Grid } from "@mui/material";
import LineChart from "components/Chart/LineChart";
import GeneralSummary from "components/GeneralSummary/GeneralSummary";
import OrdersSummary from "components/OrdersWidget/OrdersWidget";
import UsersSummary from "components/UsersSummary/UsersSummary";
import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import { getUserStats } from "services/user.requests";
import { initialUsersStats } from "utils/users.plot";
const Home = () => {
  const [userStats, setUserStats] = useState(initialUsersStats);
  const stats = useRef(userStats);
  useEffect(() => {
    const getStats = async () => {
      try {
        const statsClone = cloneDeep(stats.current);
        const res = await getUserStats();
        res.data.forEach((item) => {
          statsClone[item._id - 1]["Active Users"] = item.total;
        });
        setUserStats(statsClone);
      } catch (error) {
        console.log(error);
      }
    };
    getStats();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} lg={4}>
        <GeneralSummary />
      </Grid>
      <Grid item xs={12} sm={12} lg={8}>
        <LineChart
          rawData={userStats}
          title="User Analytics (past 12 months)"
          grid
          dataKey="Active Users"
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <UsersSummary />
      </Grid>
      <Grid item xs={12} sm={6} lg={6}>
        <OrdersSummary />
      </Grid>
    </Grid>
  );
};

export default Home;
