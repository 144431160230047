import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, ButtonGroup } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

type TDeleteHandler = (id: string) => void;
const ProductsColumns = (handleDelete: TDeleteHandler) => [
  { field: "_id", headerName: "ID", width: 200 },
  {
    field: "userId",
    headerName: "User ID",
    width: 200,
  },
  { field: "amount", headerName: "Amount", width: 100 },
  { field: "address", headerName: "Address", width: 140 },
  {
    field: "status",
    headerName: "Status",
    width: 80,
  },

  {
    field: "action",
    headerName: "Action",
    width: 100,
    renderCell: (params: any) => {
      return (
        <ButtonGroup variant="text" aria-label="text button group">
          <Button
            to={"/order/" + params.row._id}
            component={RouterLink}
            variant="text"
            color="success"
            size="medium"
          >
            <EditIcon />
          </Button>
          <Button
            size="medium"
            variant="text"
            color="error"
            onClick={() =>
              window.confirm("Are you sure you want to delete this item?") &&
              handleDelete(params.row._id)
            }
          >
            <DeleteIcon />
          </Button>
        </ButtonGroup>
      );
    },
  },
];
export default ProductsColumns;
