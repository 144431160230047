import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { IIncome, IMainPanelIndicators } from "interfaces";
import { useEffect, useState } from "react";
import { getOrdersIncome } from "services/order.request";
import { getRevenueIndicator, initialIndicators } from "utils/indicators";

const GeneralSummary = () => {
  const [indicators, setIndicators] = useState<IMainPanelIndicators | null>(
    initialIndicators
  );

  useEffect(() => {
    const updateIndicators = (income: IIncome[], timeframe: string) => {
      const newIndicators = {
        //TODO: Add new indicators logic
        //Dummy indicators, not yet implemented
        ...initialIndicators,
        Revenue: getRevenueIndicator(income, timeframe),
      };
      setIndicators(newIndicators);
    };
    const getIncome = async () => {
      try {
        const { status, data: incomeRes } = await getOrdersIncome();
        if (status === 200) {
          updateIndicators(incomeRes, "month");
        } else setIndicators(null);
      } catch (error) {
        console.log(error);
      }
    };
    getIncome();
  }, []);

  return (
    <Box
      height={"100%"}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {indicators ? (
        Object.entries(indicators).map(([indicator, data], index) => (
          <Stack
            component={Paper}
            justifyContent="center"
            sx={{
              p: 4,
            }}
            key={index}
          >
            <Typography variant="h6" noWrap component="span">
              {indicator}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Typography variant="h4" noWrap component="span">
                ${data.quantity}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" noWrap component="span">
                  {Math.floor(data.trend)}%
                </Typography>
                {data.trend < 0 ? (
                  <ArrowDownward style={{ color: "red" }} />
                ) : (
                  <ArrowUpward style={{ color: "green" }} />
                )}
              </Box>
            </Stack>
            <Typography variant="subtitle1" noWrap component="span">
              Compared to last {data.timeframe}
            </Typography>
          </Stack>
        ))
      ) : (
        <Paper sx={{ p: 4, marginTop: 2 }}>
          <Typography variant="h6" noWrap component="span">
            No indicators could be fetched
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default GeneralSummary;
