// import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import JapanesePattern from "assets/japanesePattern.png";
import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { loginDispatcher, selectCurrentUser } from "state";
import { selectUserState } from "../../state/selectors/user.selectors";

const styles = {
  paperContainer: {
    backgroundImage: `url(${JapanesePattern})`,
  },
};

const Login = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const { error } = useSelector(selectUserState);

  //Component state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState<IUIMessage>({
    text: "",
    color: "info",
  });

  // Handlers
  const handleLogin = () => {
    loginDispatcher(dispatch, { username, password });
    // Server cookie should be created
  };
  const handleLoginClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    handleLogin();
  };
  // Handlers
  const handleLoginKeyDown: KeyboardEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    handleLogin();
  };

  // Re-renders/redirects
  useEffect(() => {
    error &&
      setMessage({
        text: "Login failure! Check your username and password are correct",
        color: "error",
      });
  }, [error]);
  useEffect(() => {
    if (currentUser) {
      setMessage({ text: "Login success!", color: "success" });
      navigate("/");
    }
  }, [currentUser, navigate]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
      style={styles.paperContainer}
    >
      <Box
        component={Paper}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          "& .MuiTextField-root": { m: 1, width: "30ch" },
        }}
      >
        <FormControl>
          <Typography variant="h5">Dashboard login:</Typography>
          <TextField
            id="username"
            label="Username"
            defaultValue=""
            placeholder="username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            type={"password"}
            id="password"
            label="Password"
            defaultValue=""
            placeholder="username"
            variant="standard"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Stack
            justifyContent={"center"}
            sx={{
              margin: "10px",
            }}
          >
            {message.text && (
              <Chip
                sx={{ justifySelf: "center" }}
                label={message.text}
                color={message.color}
                deleteIcon={<DoneIcon />}
              />
            )}
          </Stack>
          <Button
            onClick={handleLoginClick}
            onKeyDown={handleLoginKeyDown}
            variant="contained"
            color="primary"
          >
            Login
          </Button>
        </FormControl>
      </Box>
    </Box>
  );
};

export default Login;
