import { RootState } from "state";

export const selectUserState = (state: RootState) => state.user;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectLoggedUserId = (state: RootState) =>
  state.user.currentUser?._id;
export const selectIsAdmin = (state: RootState) =>
  state.user.currentUser?.isAdmin;
export const selectUsersList = (state: RootState) => state.user.usersList;
export const selectUserById = (userId: string) => (state: RootState) =>
  state.user.usersList.find((user) => user._id === userId);
