import DoneIcon from "@mui/icons-material/Done";
import {
  Chip,
  Container,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { IOrderItem } from "interfaces";
import { IOrder } from "interfaces/orders";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectOrderById,
  selectOrdersState,
  updateOrderDispatcher,
} from "state";
import OrderUpdateForm from "components/Forms/OrderUpdateForm";
import { useCallback } from "react";

const EditOrder: React.FC = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //   let location = useLocation();
  // let urlParams = useParams();
  // let [urlSearchParams] = useSearchParams();
  const orderId = location.pathname.split("/")[2] as string;
  const order = useSelector(selectOrderById(orderId));
  const { error } = useSelector(selectOrdersState);

  // Component state
  const [message, setMessage] = useState<IUIMessage>({
    text: "",
    color: "info",
  });
  const [input, setInput] = useState<Partial<IOrder>>({});

  // Handlers(form)
  const handleOrderInputChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (e) => setInput({ ...input, [e.target.name]: e.target.value }),
      [input]
    );

  const handleOrderSubmit: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      setMessage({ text: "Order update successful!", color: "success" });
      updateOrderDispatcher(orderId, input, dispatch);
    },
    [orderId, input, dispatch]
  );

  const handleOrderProductChange = useCallback(
    (products: IOrderItem[]) => setInput({ ...input, products }),
    [input]
  );

  //Re-renders
  useEffect(() => {
    setInput({ products: order?.products });
  }, [order]);
  useEffect(() => {
    if (error)
      setMessage({
        text: "An error occurred while updating order data",
        color: "error",
      });
  }, [error]);
  useEffect(() => {
    if (!order) {
      navigate(`/notfound?e=${JSON.stringify(Error("400: Unknown error"))}`);
    }
  }, [order, navigate]);
  return (
    <>
      {order && (
        <>
          <Container>
            <Typography sx={{ m: 1 }} variant="h4">
              Order
            </Typography>
            <Stack component={Paper} sx={{ p: 2 }} justifyContent="center">
              <OrderUpdateForm
                order={order}
                items={input!.products as IOrderItem[]}
                handleInputChange={handleOrderInputChange}
                handleOrderSubmit={handleOrderSubmit}
                handleOrderProductChange={handleOrderProductChange}
              />
              <Stack
                sx={{
                  margin: "10px",
                }}
              >
                {message.text && (
                  <Chip
                    label={message.text}
                    color={message.color}
                    deleteIcon={<DoneIcon />}
                  />
                )}
              </Stack>
            </Stack>
          </Container>
          <Toolbar />
        </>
      )}
    </>
  );
};

export default EditOrder;
