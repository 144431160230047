import { Box, Toolbar } from "@mui/material";
import ResponsiveSidebar from "components/Sidebar/ResponsiveSidebar";
import { DRAWER_WIDTH } from "config";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import { selectCurrentUser } from "../../state/selectors/user.selectors";
import Navbar from "../Navbar/Navbar";
interface LayoutProps {}
export interface LayoutSettingsProps {
  settings: {
    toggleSideBar: any;
    openSidebar: boolean;
    drawerWidth: number;
  };
}
const Layout: React.FC<LayoutProps> = () => {
  const drawerWidth = DRAWER_WIDTH;
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const toggleSideBar = (): void => {
    setOpenSidebar(!openSidebar);
  };
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);

  const redirect = useCallback(() => {
    navigate("/login");
  }, [navigate]);
  useEffect(() => {
    if (!currentUser) redirect();
  }, [currentUser, redirect]);

  const settings = {
    toggleSideBar,
    openSidebar,
    drawerWidth,
  };
  return (
    <>
      {currentUser && (
        <div>
          <Navbar settings={settings} />
          <Box sx={{ display: "flex", height: "100%" }}>
            <ResponsiveSidebar settings={settings} />
            <Box
              sx={{
                margin: "20px",
                width: {
                  xs: "100vw",
                  lg: `calc(100vw - ${drawerWidth}px)`,
                },
                height: "100%",
              }}
            >
              <Toolbar />

              <Outlet />
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default Layout;
