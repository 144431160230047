import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import { truncate } from "lodash";
import { Link as RouterLink } from "react-router-dom";

type TDeleteHandler = (id: string) => void;
const UserColumns = (handleDelete: TDeleteHandler) => [
  { field: "_id", headerName: "ID", width: 200 },
  {
    field: "user",
    headerName: "User",
    width: 200,
    renderCell: (params: any) => (
      <List component={Stack} direction="row">
        <ListItem disablePadding>
          <ListItemAvatar>
            <Avatar alt={params.row.username} src={params.row.img} />
          </ListItemAvatar>
        </ListItem>
        <ListItem disablePadding>
          <ListItemText
            secondary={truncate(params.row.username, { length: 40 })}
          />
        </ListItem>
      </List>
    ),
  },
  { field: "email", headerName: "Email", width: 200 },
  {
    field: "active",
    headerName: "Status",
    width: 90,
  },
  {
    field: "isAdmin",
    headerName: "Admin",
    width: 90,
  },

  {
    field: "action",
    headerName: "Action",
    width: 150,
    renderCell: (params: any) => {
      return (
        <ButtonGroup variant="text" aria-label="text button group">
          <Button
            to={"/user/" + params.row._id}
            component={RouterLink}
            variant="text"
            color="success"
            size="medium"
          >
            <EditIcon />
          </Button>
          <Button
            size="medium"
            variant="text"
            color="error"
            onClick={() => handleDelete(params.row._id)}
          >
            <DeleteIcon />
          </Button>
        </ButtonGroup>
      );
    },
  },
];

export default UserColumns;
