import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { INewUser } from "interfaces";

interface INewUserFormProps {
  input: INewUser;
  passwordCheck: {
    password: string;
    repeat: string;
  };
  handlePasswordCheck: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  handleUserInputChange: ChangeEventHandler<HTMLInputElement>;
  handleNewUserSubmit: MouseEventHandler<HTMLButtonElement>;
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
}
const NewUserForm: React.FC<INewUserFormProps> = ({
  input,
  passwordCheck,
  handlePasswordCheck,
  handleUserInputChange,
  handleNewUserSubmit,
  handleFileChange,
}) => {
  return (
    <Stack
      component="form"
      alignItems="center"
      justifyContent="center"
      sx={{
        maxWidth: "100%",
        flexWrap: "wrap",
        "& .MuiTextField-root": { m: 2 },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack direction={{ xs: "column", lg: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& .MuiTextField-root": { width: { xs: "40ch", lg: "30ch" } },
          }}
        >
          <TextField
            name="username"
            id="outlined-user-username"
            label="Username"
            placeholder="e.g. johndoe2000"
            onChange={handleUserInputChange}
          />
          <TextField
            name="email"
            id="outlined-user-email"
            label="Email"
            placeholder="johndoe@mail.com"
            onChange={handleUserInputChange}
          />
          <TextField
            name="phone"
            id="outlined-user-phone"
            label="Phone"
            placeholder="+1 234 5678"
            onChange={handleUserInputChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& .MuiTextField-root": { width: { xs: "35ch", lg: "45ch" } },
          }}
        >
          <TextField
            name="fullname"
            id="outlined-user-fullname"
            label="Full name"
            placeholder="John Doe"
            onChange={handleUserInputChange}
          />
          <TextField
            name="password"
            id="outlined-user-password"
            label="Password"
            placeholder="34F%^dfr@dg52d%"
            error={passwordCheck.password !== passwordCheck.repeat}
            onChange={handlePasswordCheck}
          />
          <TextField
            name="repeat"
            id="outlined-user-password"
            label="Repeat Password"
            placeholder="34F%^dfr@dg52d%"
            error={passwordCheck.password !== passwordCheck.repeat}
            helperText={
              passwordCheck.password !== passwordCheck.repeat &&
              "Passwords don't match"
            }
            onChange={handlePasswordCheck}
          />
        </Box>

        <Stack>
          <Stack
            direction={"row"}
            sx={{
              "& .MuiTextField-root": {
                width: "15ch",
              },
            }}
          >
            <TextField
              name="active"
              id="outlined-select-active"
              select
              label="Active"
              defaultValue="true"
              helperText="'Yes' to enable"
              onChange={handleUserInputChange}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
            <TextField
              name="isAdmin"
              id="outlined-select-active"
              select
              label="Admin. permissions"
              defaultValue="false"
              helperText="'Yes' to enable"
              onChange={handleUserInputChange}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </TextField>
          </Stack>
          <TextField
            name="address"
            id="outlined-user-address"
            label="Address"
            multiline
            rows={4}
            placeholder="New York/USA"
            onChange={handleUserInputChange}
          />
        </Stack>
      </Stack>
      <Box>
        {input.img && (
          <img src={input.img} alt={`Uploaded for ${input.username}`} />
        )}
      </Box>
      <Stack spacing={2} direction="row">
        <Button variant="contained" component="label">
          Upload Image
          <input type="file" hidden onChange={handleFileChange} />
        </Button>

        <Button onClick={handleNewUserSubmit} variant="contained">
          Create new user account
        </Button>
      </Stack>
    </Stack>
  );
};

export default NewUserForm;
