import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
} from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import {
  Avatar,
  Box,
  Chip,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { IUser } from "interfaces";
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserById } from "services";
import { selectUserById, updateUserDispatcher } from "state";
import { format } from "timeago.js";
import { selectUserState } from "state/selectors/user.selectors";
import { fileUpload } from "utils/file.upload";
import UserUpdateForm from "components/Forms/UpdateUserForm";

const UserShow: React.FC<{ user: IUser }> = ({ user }) => (
  <>
    <List subheader={<ListSubheader>Account</ListSubheader>}>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={user.img} alt={user.username} />
        </ListItemAvatar>
        <ListItemText primary={user.username} secondary={"Username"} />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <CalendarToday />
        </ListItemAvatar>
        <ListItemText
          primary={format(user.createdAt)}
          secondary={"Creation date"}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <MailOutline />
        </ListItemAvatar>
        <ListItemText primary={user.email} secondary={"Email address"} />
      </ListItem>
    </List>
    <List subheader={<ListSubheader>User Details</ListSubheader>}>
      <ListItem>
        <ListItemAvatar>
          <PermIdentity />
        </ListItemAvatar>
        <ListItemText
          primary={user.fullname || "Not given"}
          secondary={"Full name"}
        />
      </ListItem>

      <ListItem>
        <ListItemAvatar>
          <PhoneAndroid />
        </ListItemAvatar>
        <ListItemText
          primary={user.phone || "Not given"}
          secondary={"Phone number"}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <LocationSearching />
        </ListItemAvatar>
        <ListItemText
          primary={user.address || "Not given"}
          secondary={"Address"}
        />
      </ListItem>
    </List>
  </>
);

export default function EditUser() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userId = location.pathname.split("/")[2] as string;
  const userCached = useSelector(selectUserById(userId));
  const { error, isFetching } = useSelector(selectUserState);

  const [message, setMessage] = useState<IUIMessage>({
    text: "",
    color: "success",
  });
  const [userData, setUserData] = useState<IUser | null>(null);
  const [input, setInput] = useState<Partial<IUser>>({});

  const handleUserInputChange: ChangeEventHandler<HTMLInputElement> = (e) =>
    setInput({ ...input, [e.target.name]: e.target.value });
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = (e?.target?.files as FileList)[0] as File;
    setMessage({ text: "Uploading file", color: "info" });
    fileUpload(
      file,
      (downloadURL) => {
        setInput({ ...input, img: downloadURL });
      },
      (text, color) => setMessage({ text, color })
    );
  };
  const handleUserSubmit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setMessage({ text: "User update successful!", color: "success" });
    updateUserDispatcher(userId, input, dispatch);
    error &&
      setMessage({ text: "Error: Couldn't update user", color: "error" });
  };

  useEffect(() => {
    if (error)
      setMessage({
        text: "An error occurred while updating user data",
        color: "error",
      });
  }, [error]);
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await getUserById(userId);
        setUserData(res.data);
      } catch (error) {
        navigate("/notfound");
        console.log(error);
      }
    };
    if (!userCached) getUser();
    else setUserData(userCached);
  }, [userId, userCached, navigate]);
  return (
    <>
      {userData ? (
        <>
          <Container>
            <Typography sx={{ m: 1 }} variant="h4">
              Edit User
            </Typography>

            <Stack
              component={Paper}
              sx={{
                display: "flex",
                flexDirection: { base: "column", lg: "row" },
                justifyContent: "center",
              }}
            >
              <Box>
                <UserShow user={userData} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                p={4}
              >
                <Typography variant="subtitle2" sx={{ m: 2 }}>
                  Update Form
                </Typography>
                <UserUpdateForm
                  user={userData}
                  isFetching={isFetching}
                  handleInputChange={handleUserInputChange}
                  handleUserSubmit={handleUserSubmit}
                  handleFileChange={handleFileChange}
                />
              </Box>
            </Stack>
            <Stack
              justifyContent={"center"}
              sx={{
                margin: "10px",
              }}
            >
              {message.text && (
                <Chip
                  label={message.text}
                  color={message.color}
                  deleteIcon={<DoneIcon />}
                />
              )}
            </Stack>
          </Container>
        </>
      ) : (
        <Typography sx={{ m: 1 }} variant="h4">
          Loading User data...
        </Typography>
      )}
    </>
  );
}
