import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import * as React from "react";
import { listOptions } from "./ListOptions";
import Divider from "@mui/material/Divider";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

const MenuListPanel: React.FC = () => {
  const [selectedTag, setSelectedTag] = React.useState(
    listOptions[0].list[0].tag
  );
  return (
    <>
      {listOptions.map((subLists) => (
        <div key={subLists.header}>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                component="div"
                id={`nested-list-subheader-${subLists.header}`}
              >
                {subLists.header}
              </ListSubheader>
            }
          >
            {subLists.list.map((item) => (
              <Link
                style={{ cursor: item.active ? "pointer" : "not-allowed" }}
                component={RouterLink}
                key={item.tag}
                to={item.active ? item.link : "#"}
                underline="none"
                onClick={() => setSelectedTag(item.tag)}
              >
                <ListItemButton
                  selected={selectedTag === item.tag}
                  disabled={!item.active}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.tag} />
                </ListItemButton>
              </Link>
            ))}
          </List>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default MenuListPanel;
