import Layout from "components/Layout/Layout";
import Login from "pages/login/Login";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import NewProduct from "./pages/product/new/NewProduct";
import NewUser from "./pages/user/new/NewUser";
import EditProduct from "./pages/product/edit/EditProduct";
import Products from "./pages/products/Products";
import EditUser from "./pages/user/edit/EditUser";
import Users from "./pages/users/Users";
import NotFound from "pages/notFound/NotFound";
import { CssBaseline } from "@mui/material";
import Orders from "./pages/orders/Orders";
import EditOrder from "./pages/order/edit/EditOrder";

export interface ISidebarState {
  toggleSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  openSidebar: boolean;
}
const styles = {
  paperContainer: {
    // backgroundImage: `url(${JapanesePattern})`,
    backgroundColor: "#f5f5ee",
    height: "100vh",
    maxHeight: "100vh",
    overflow: "auto",
  },
};
const Background: React.FC = ({ children }) => (
  <div style={styles.paperContainer}>
    <CssBaseline />
    {children}
  </div>
);

function App() {
  return (
    <Background>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Home route */}
            <Route index element={<Home />} />

            {/* Users routes */}
            <Route path="users" element={<Users />} />
            <Route path="user">
              <Route index element={<NotFound />} />

              <Route path=":userId" element={<EditUser />} />
              <Route path="new" element={<NewUser />} />
            </Route>
            <Route path="user/" element={<NotFound />} />

            {/* Products routes */}
            <Route path="products" element={<Products />} />
            <Route path="product">
              <Route index element={<NotFound />} />
              <Route path=":productId" element={<EditProduct />} />
              <Route path="new" element={<NewProduct />} />
            </Route>
            <Route path="product/" element={<NotFound />} />

            {/* Orders routes */}
            <Route path="orders" element={<Orders />} />
            <Route path="order">
              <Route index element={<NotFound />} />
              <Route path=":orderId" element={<EditOrder />} />
              <Route path="order/" element={<NotFound />} />
            </Route>
          </Route>
          {/* Login route */}
          <Route path="login" element={<Login />} />
          {/* Any other route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Background>
  );
}

export default App;
