import DeleteIcon from "@mui/icons-material/Delete";
import { Button, MenuItem, Stack, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { IOrder, IOrderItem } from "interfaces";
import { ChangeEventHandler, MouseEventHandler } from "react";

const ProductItemColumns = (
  items: IOrderItem[],
  handleOrderProductChange: (products: IOrderItem[]) => void
) => [
  { field: "productId", headerName: "PID", flex: 1, minWidth: 100 },
  { field: "quantity", headerName: "Quantity", flex: 1 },
  { field: "color", headerName: "Color", flex: 1 },
  { field: "size", headerName: "Size", flex: 1 },
  {
    field: "action",
    headerName: "Action",
    minWidth: 180,
    renderCell: (params: any) => {
      return (
        <Button
          size="medium"
          variant="text"
          color="error"
          onClick={() =>
            window.confirm("Are you sure you want to delete this item?") &&
            handleOrderProductChange(
              items.filter((item) => item.productId !== params.row.productId)
            )
          }
        >
          <DeleteIcon />
        </Button>
      );
    },
  },
];

interface IOrderUpdateFormProps {
  order: IOrder;
  items: IOrderItem[];
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleOrderSubmit: MouseEventHandler<HTMLButtonElement>;
  handleOrderProductChange: (products: IOrderItem[]) => void;
}

const OrderUpdateForm: React.FC<IOrderUpdateFormProps> = ({
  order,
  items,
  handleInputChange,
  handleOrderSubmit,
  handleOrderProductChange,
}) => (
  <Stack
    component="form"
    direction={"column"}
    sx={{
      width: "100%",
      "& .MuiTextField-root": { m: 1 },
    }}
    noValidate
    autoComplete="off"
  >
    <Stack direction={{ base: "column", md: "row" }} flexWrap="wrap">
      <TextField
        name="_id"
        sx={{ width: "100%", flex: { base: 0, md: 1 } }}
        id="outlined-order-id"
        label="Order ID"
        defaultValue={order._id}
        disabled
      />
      <TextField
        onChange={handleInputChange}
        name="amount"
        sx={{ width: "100%", flex: { base: 0, md: 1 } }}
        id="outlined-order-amount"
        label="Amount($)"
        defaultValue={order.amount}
      />
      <TextField
        onChange={handleInputChange}
        name="status"
        sx={{ width: "100%", flex: { base: 0, md: 1 } }}
        id="outlined-select-status"
        select
        label=""
        defaultValue={order.status}
        helperText="Please select your order status"
      >
        <MenuItem value="pending">Pending</MenuItem>
        <MenuItem value="declined">Declined</MenuItem>
        <MenuItem value="approved">Approved</MenuItem>
      </TextField>
    </Stack>

    <Stack width={"100%"}>
      <TextField
        name="address"
        style={{ flex: 1 }}
        id="outlined-order-address"
        label="Address"
        placeholder={order.address}
        multiline
        rows={3}
        value=""
        onChange={handleInputChange}
      />
    </Stack>
    <DataGrid
      rows={items}
      autoHeight
      getRowId={(row) => row.productId}
      disableSelectionOnClick
      columns={ProductItemColumns(order.products, handleOrderProductChange)}
    />
    <Stack alignItems="center" justifyContent="center" mt={"20px"}>
      <Button
        sx={{ width: 240 }}
        color="primary"
        onClick={handleOrderSubmit}
        variant="contained"
      >
        Update
      </Button>
    </Stack>
  </Stack>
);

export default OrderUpdateForm;
